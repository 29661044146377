import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { MiddleEntry } from "../components/MiddleEntry";

type TitleProps = {
  site: {
    siteMetadata: {
      exampleUrl: string
    }
  }
}

type SlideProps = {
  slide: boolean
}

const Treatment: React.FC<SlideProps> = ({ slide }) => {
  const testpage = {
    title: "Anwendung",
    description:
      "Wir sind \
    umgeben von Energie, sei es kosmische, Sonnenenergie, elektrische, magnetische oder universelle. Mit einem Reikigrad erhälst du die Kenntniss die universelle Energie zu verwenden. \
    Reiki wird mit den Händen am Körper angewendet. Man geht unterschiedliche Position durch. Oft wird die Stelle warm, die berührt wird. (Das Licht im Foto ist nur zur Illustration) Man \
    kann Reiki bei sich selbst oder anderen anwenden. Aber auch Haustiere sind für Reiki sehr empfänglich und werden dadurch meiner \
    Erfahrung nach ruhiger und ausgeglichener.",
  }

  return (
    <div>
      <MiddleEntry
        data={testpage}
        key={`p-item-index-20`}
        even={true}
        slide={slide}
      >
        <StaticImage
          src="../images/man_2.jpg"
          width={300}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Behandlung Brustkorb"
          style={{ marginBottom: `1.45rem`, borderRadius: `10px` }}
        />
        <StaticImage
          src="../images/man_1.jpg"
          width={300}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Behandlung Kopf"
          style={{ marginBottom: `1.45rem`, borderRadius: `10px` }}
        />
        <StaticImage
          src="../images/dog_2.jpg"
          width={300}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Behandlung Hund"
          style={{ marginBottom: `1.45rem`, borderRadius: `10px` }}
        />

        <StaticImage
          src="../images/cat_1.jpg"
          width={300}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="Behandlung Katze"
          style={{ marginBottom: `1.45rem`, borderRadius: `10px` }}
        />
      </MiddleEntry>
    </div>
  )
}

export default Treatment
